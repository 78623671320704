<template>
  <a :href="href" class="flex-col justify-center block w-1/2 mb-8 cursor-pointer sm:w-1/3 md:w-1/4 lg:w-1/5 group">
    <div
      class="flex items-center justify-center w-24 h-24 mx-auto mb-4 transition bg-white shadow rounded-xl group-hover:shadow-md"
    >
      <img class="w-12 h-12 grayscale-1 group-hover:grayscale-0" :src="image" :alt="title" />
    </div>
    <div class="text-xs font-bold text-center text-gray-800">{{ title }}</div>
    <div class="text-xs text-center text-gray-500">{{ subtitle }}</div>
  </a>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    href: String,
    image: String,
    title: String,
    subtitle: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
