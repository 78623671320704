<template>
  <div id="app">
    <div class="flex-grow">
      <main role="main">
        <div class="container flex justify-center mx-auto mt-16">
          <img class="h-16" src="./assets/logos/cnj.svg" alt="CNJ" />
        </div>
        <div class="container flex justify-center mx-auto mt-20">
          <div class="flex w-8/12 px-6 py-3 bg-white rounded-full shadow">
            <div class="w-6 h-6 mr-2 searchIcon"></div>
            <input
              v-model="input"
              @keypress.enter="search"
              type="text"
              :placeholder="$t('search')"
              class="w-full outline-none"
            />
          </div>
        </div>

        <div class="container flex flex-wrap mx-auto my-20">
          <icon
            v-for="(icon, index) in $t('icons')"
            :key="index"
            :image="icon.logo"
            :href="icon.url"
            :title="icon.title"
            :subtitle="icon.subtitle"
          />
        </div>

        <div class="container mb-32">
          <h1 class="mb-12 text-4xl font-bold text-center text-gray-800">{{ $t('pm_title') }}</h1>
          <ul class="flex flex-wrap justify-start">
            <person
              v-for="(person, index) in $t('pms')"
              :key="index"
              :image="person.image"
              :href="person.url"
              :name="person.name"
            />
          </ul>
        </div>
      </main>
    </div>

    <footer class>
      <div class="container flex justify-center mx-auto my-4">
        <span class style="margin-bottom: 50px">
          <a href="https://umbraco.com" target="_blank">
            <img
              src="./assets/logos/umbraco.png"
              alt="Registered_Partner_logo_transparent_background"
              width="200px"
              height
            />
          </a>
        </span>
      </div>
      <div class="container flex justify-center mx-auto my-4 text-xs text-gray-600">
        <span class>CNJ d.o.o. &copy; {{ new Date().getFullYear() }}</span>
      </div>
    </footer>
  </div>
</template>

<script>
import Icon from './components/Icon.vue'
import Person from './components/Person.vue'

export default {
  name: 'App',
  components: {
    Person,
    Icon
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    search() {
      window.location.href = 'https://www.google.com/search?q=' + encodeURI(this.input)
    }
  }
}
</script>
