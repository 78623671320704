<template>
  <div class="flex-col block w-1/2 cursor-pointer sm:w-1/3 md:w-1/4 lg:w-1/5 justify-centergroup">
    <a
      :href="href"
      target="_blank"
      class="flex flex-col items-center justify-center w-32 h-32 mx-auto mb-4 transition bg-white shadow group sm:w-40 sm:h-40 rounded-xl hover:shadow-md"
    >
      <li class="flex flex-col items-center justify-center">
        <img
          v-if="image"
          :src="image"
          class="object-cover w-16 h-16 rounded-full sm:h-20 sm:w-20 grayscale-1 group-hover:grayscale-0"
        />
        <div
          v-else
          class="w-16 h-16 bg-teal-200 rounded-full sm:h-20 sm:w-20 grayscale-1 group-hover:grayscale-0"
        ></div>
        <span class="mt-2 font-bold text-gray-700 sm:mt-4">{{ name }}</span>
      </li>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: {
    href: String,
    image: String,
    name: String
  }
}
</script>
